export function createPixelArray(imgData, pixelCount, quality) {
    const pixels = imgData;
    const pixelArray = [];

    for (let i = 0, offset, r, g, b, a; i < pixelCount; i = i + quality) {
        offset = i * 4;
        r = pixels[offset + 0];
        g = pixels[offset + 1];
        b = pixels[offset + 2];
        a = pixels[offset + 3];

        // If pixel is mostly opaque and not white
        if (typeof a === 'undefined' || a >= 125) {
            if (!(r > 250 && g > 250 && b > 250)) {
                pixelArray.push([r, g, b]);
            }
        }
    }
    return pixelArray;
}

export const checkUrl = (url: string) => {
    return new Promise<void>((resolve, reject) => {
        if (!url) reject();
        // const request = new XMLHttpRequest();
        // request.open('GET', url, true);
        // request.send();
        // request.onload = function () {
        //     if (request.status == 200) {
        //         resolve(request.status);
        //     } else {
        //         reject(request.status);
        //     }
        // };
        const image = new Image();
        image.src = url;
        image.onload = () => {
            resolve();
        };
        image.onerror = () => {
            reject();
        };
    });
};
