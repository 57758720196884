import { Box, ClickAwayListener, useTheme } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import minusButton from 'assets/svg/minus-button1.svg';
import plusButton from 'assets/svg/plus-button1.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { Headings, LargeText, MediumText, SmallText } from 'components/atoms/Typography';
import { useCartService } from 'services/cart/useCartService';
import { Add, Remove } from '@mui/icons-material';

interface IItemQuantityComponentProps {
    onChange?: (value: number) => void;
    zeroBased?: boolean;
    vertical?: boolean;
    takeSpace?: boolean;
    initial?: number;
    inCart?: boolean;
    disabled?: boolean;
    value?: number;
    minValue?: number;
    onRemoveItem?: () => void;
    containerWidth?: number;
    valuePosition?: 'right' | 'center';
    openAllTime?: boolean;
    canRemove?: boolean;
}

const CartItemQuantityComponent = ({
    onChange,
    zeroBased = false,
    vertical = false,
    takeSpace,
    initial = zeroBased ? 0 : 1,
    inCart = false,
    disabled = false,
    value,
    minValue,
    onRemoveItem,
    containerWidth = 120,
    valuePosition = 'right',
    openAllTime,
    canRemove = true,
}: IItemQuantityComponentProps) => {
    const [quantity, setQuantity] = useState<number>(value || initial);
    const [quantityClicked, setQuantityClicked] = useState<boolean>(openAllTime ?? false);
    const theme = useTheme();

    const { removeItem } = useCartService();
    useEffect(() => {
        setQuantity(value ?? initial);
    }, [value]);

    const increase = () => {
        if (!disabled && quantityClicked) {
            const _quantity = quantity + 1;
            setQuantity(_quantity);
            if (onChange) {
                onChange(_quantity);
            }
        }
    };

    const decrease = () => {
        if (minValue !== quantity) {
            if (!disabled && quantity > (zeroBased ? 0 : 1)) {
                const _quantity = quantity - 1;
                setQuantity(_quantity);
                if (onChange) {
                    onChange(_quantity);
                }
            }
        }
    };
    const handleRemoveItem = () => {
        onRemoveItem?.();
    };

    const deleteWidth = canRemove ? containerWidth * 0.2 : 0;
    const plusWidth = canRemove ? containerWidth * 0.2 : containerWidth * 0.25;
    const minusWidth = canRemove ? containerWidth * 0.2 : containerWidth * 0.25;
    const plusMinusWidth = plusWidth + minusWidth;

    const totalPadding = canRemove ? 12 : 8;

    const leftWidth = containerWidth - (plusMinusWidth + deleteWidth) - totalPadding;

    const valueTranslate =
        valuePosition === 'center' ? containerWidth * 0.5 - minusWidth - leftWidth * 0.5 : deleteWidth + plusWidth;
    const plusTranslate = valuePosition === 'center' ? '-100%' : '100%';
    const minusTranslate = valuePosition === 'center' ? '0' : '100%';
    const binTranslate = valuePosition === 'center' ? '-100%' : '100%';

    return (
        <ClickAwayListener
            onClickAway={() => {
                if (!openAllTime) {
                    setQuantityClicked(false);
                }
            }}
        >
            <Box
                position="relative"
                width={containerWidth}
                display="flex"
                alignItems="center"
                overflow="visible"
                borderRadius="5px"
                zIndex={0}
                py={0.7}
            >
                <Box
                    position="absolute"
                    width="100%"
                    height="100%"
                    borderRadius="5px"
                    style={{
                        transform: quantityClicked ? 'scale(1,1)' : 'scale(0.25,1)',
                        backgroundColor: quantityClicked ? 'rgba(0, 157, 224, 0.08)' : 'transparent',
                    }}
                    sx={{
                        transition: `transform 100ms ease-in-out, background-color 100ms ease-in-out`,
                    }}
                ></Box>
                <Box
                    // src={minusButton}
                    zIndex={999}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    ml={0.5}
                    sx={{
                        width: minusWidth,
                        height: minusWidth,
                        opacity: quantityClicked ? 1 : 0,
                        cursor: 'pointer',
                        transition: 'opacity 0.1s ease-in, opacity 0.01s ease-out',
                    }}
                    onClick={decrease}
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'white',
                            color: 'primary.main',
                            borderRadius: '100%',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            },
                            transform: quantityClicked ? 'translateX(0)' : `translateX(${minusTranslate})`,
                            transition: `opacity ${
                                quantityClicked ? '50ms ease-out' : '10ms ease-in'
                            }, z-index 0.1s ease-in-out, transform ${quantityClicked ? '50ms ease-out' : '5ms ease-in'}`,
                        }}
                    >
                        <Remove />
                    </Box>
                </Box>
                <Box
                    flex={1}
                    width={leftWidth}
                    // height={leftWidth}
                    py={0.2}
                    display="flex"
                    zIndex={2}
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => setQuantityClicked(true)}
                    sx={{
                        transform: quantityClicked ? 'translateX(0px)' : `translateX(${valueTranslate}px)`,
                        backgroundColor: quantityClicked ? 'transparent' : '',
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderColor: quantityClicked ? 'transparent' : 'lightgray',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        transition: theme.transitions.create(['transform', 'background-color', 'border-color'], {
                            duration: theme.transitions.duration.shortest,
                            easing: theme.transitions.easing.sharp,
                        }),
                        transitionDuration: '75ms',
                    }}
                >
                    <MediumText
                        sx={{ fontWeight: { xs: '500', md: '600' }, color: 'primary.main' }}
                        fontSize={`${containerWidth / 120}rem`}
                    >
                        {quantity}
                    </MediumText>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    zIndex={quantityClicked ? 999 : 0}
                    mr={canRemove ? 0 : 0.5}
                    sx={{
                        width: plusWidth,
                        height: plusWidth,
                        cursor: 'pointer',
                        opacity: quantityClicked ? 1 : 0,
                        transform: quantityClicked ? 'translateX(0)' : `translateX(${plusTranslate})`,
                        transition: `opacity ${
                            quantityClicked ? '50ms ease-out' : '10ms ease-in'
                        }, z-index 0.1s ease-in-out, transform ${quantityClicked ? '50ms ease-out' : '5ms ease-in'}`,
                    }}
                    onClick={increase}
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'white',
                            color: 'primary.main',
                            borderRadius: '100%',
                            transition: 'transform 100ms ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            },
                        }}
                    >
                        <Add />
                    </Box>
                </Box>
                {canRemove && (
                    <Box
                        zIndex={quantityClicked ? 999 : 0}
                        ml={0.5}
                        mr={0.5}
                        onClick={handleRemoveItem}
                        sx={{
                            width: containerWidth * 0.2,
                            height: containerWidth * 0.2,
                            borderRadius: '100%',
                            cursor: 'pointer',
                            opacity: quantityClicked ? 1 : 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            transform: quantityClicked ? 'translateX(0)' : `translateX(${binTranslate})`,
                            transition: `opacity ${
                                quantityClicked ? '50ms ease-out' : '5ms ease-in'
                            }, z-index 0.1s ease-in-out, ${
                                quantityClicked ? 'transform 50ms ease-out' : 'transform 5ms ease-in'
                            }`,
                        }}
                    >
                        <DeleteIcon
                            sx={{
                                color: 'primary.main',
                                cursor: 'pointer',
                                fontSize: `${(containerWidth / 120) * 1.4}em`,
                                transition: 'transform 100ms ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                },
                            }}
                        />
                    </Box>
                )}
            </Box>
        </ClickAwayListener>

        // <Box
        //     display="flex"
        //     alignItems="center"
        //     sx={{
        //         borderRadius: { xs: '10px', md: 'none' },
        //         height: { xs: '50px', md: 'auto' },
        //     }}
        // >
        // <Box
        //     component="img"
        //     src={minusButton}
        //     sx={{ width: '30px', height: '20px', borderRadius: '100%', cursor: 'pointer' }}
        //     onClick={decrease}
        //     style={{
        //         ...(takeSpace
        //             ? {
        //                   width: 'auto',
        //               }
        //             : {}),
        //     }}
        // />

        // <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        //     <LabelComponent
        //         sx={{ margin: '0 20px', fontWeight: { xs: '500', md: '600' }, color: 'primary.main' }}
        //         style={{
        //             ...(takeSpace
        //                 ? {
        //                       margin: '0 20px',
        //                   }
        //                 : {}),
        //         }}
        //     >
        //         {quantity}
        //     </LabelComponent>
        // </Box>

        // <Box
        //     component="img"
        //     src={plusButton}
        //     sx={{ width: '30px', height: '20px', borderRadius: '100%', cursor: 'pointer' }}
        //     onClick={increase}
        //     style={{
        //         ...(takeSpace
        //             ? {
        //                   width: 'auto',
        //               }
        //             : {}),
        //     }}
        // />
        // </Box>
    );
};

export default CartItemQuantityComponent;
