import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { DialogInfo } from 'redux/types/internalTypes';
import { DialogType } from 'types/dialog';
import { useStreamline } from './useStreamline';

interface SuccessFnCallback {
    (): void;
}

export const useAlertDialog = (type: DialogType = 'UNSET') => {
    const { updateDialogInfo } = useStreamline();
    const dialogInfo = useSelector((state: RootState) => state.dialogInfo.data);

    useEffect(() => {
        updateDialogInfo({ [type]: { alertVisible: false } });
    }, []);

    const successRef = useRef<SuccessFnCallback>();

    useEffect(() => {
        if (successRef.current) {
            if (dialogInfo[type]?.action === 'success') {
                updateDialogInfo({ [type]: { alertVisible: false } });
                successRef.current();
                successRef.current = null;
            }
        }
    }, [dialogInfo]);

    return {
        alert: (
            {
                title,
                description = '',
                cancelText = 'Cancel',
                successText = 'Done',
                cancelable = true,
                illustration,
                cancelColor = 'primary',
                successColor = 'secondary',
            }: Omit<DialogInfo, 'alertVisible' | 'action'>,
            handleSuccess: SuccessFnCallback = null
        ) => {
            updateDialogInfo({
                [type]: {
                    alertVisible: true,
                    title,
                    cancelText,
                    successText,
                    action: 'unset',
                    cancelable,
                    illustration,
                    description,
                    cancelColor,
                    successColor,
                },
            });
            successRef.current = handleSuccess;
        },
    };
};
