import { Box, Tooltip } from '@mui/material';
import { MediumText, SmallText } from 'components/atoms/Typography';
import { GroupedCartItem } from 'klikni-jadi-shared-stuff';
import useTranslation from 'next-translate/useTranslation';
import info from 'assets/svg/info.svg';

interface IRestaurantSubtotalProps {
    item: GroupedCartItem;
}

const CartRestaurantSubtotal = ({ item }: IRestaurantSubtotalProps) => {
    const { t } = useTranslation('common');

    // const invalid = !item.restaurantRoundup && item.restaurantTotalItemsPrice < (item.restaurantMinOrderAmount ?? 400);

    return (
        <Box color="gray" display="flex" alignItems="center">
            {!!item?.restaurantMinOrderAmount && (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <SmallText sx={{ mr: 0.5 }}>{t('minimum')}:</SmallText>
                    <SmallText>
                        {item?.restaurantMinOrderAmount} {t('mkd')}
                    </SmallText>
                </Box>
            )}
            {!!item?.roundup && (
                <Tooltip
                    title={
                        <MediumText>
                            {t('round_up')}: {item?.roundup}
                            {t('mkd')}
                        </MediumText>
                    }
                >
                    <Box mt={0.5} ml={0.5} alignItems="center" justifyContent="center">
                        <img src={info} width="15px" />
                    </Box>
                </Tooltip>
            )}
            {/* <Box display="flex" justifyContent="space-between" alignItems="center" color={_color}>
                <Box display="flex" alignItems="center">
                    <Render when={invalid}>
                        <SmallText color="#D8042A" style={{ display: 'flex', alignItems: 'center' }}>
                            <WarningOutlined />
                        </SmallText>
                    </Render>
                    <Render when={!invalid}>
                        <SmallText color="primary" style={{ display: 'flex', alignItems: 'center' }}>
                            <CheckCircle />
                        </SmallText>
                    </Render>
                    <Box ml={0.5}>
                        <SmallText semibold color={'black'}>
                            {t('subtotal')}:
                        </SmallText>
                    </Box>
                </Box>
                <SmallText semibold color={'black'}>
                    {item.restaurantTotalItemsPrice} {t('mkd')}
                    {!!item.restaurantRoundup && ` + ${item.restaurantRoundup} ${t('mkd')}`}
                </SmallText>
            </Box> */}
        </Box>
    );
};

export default CartRestaurantSubtotal;
