import { Headings, LargeText, MediumText, NormalText, SmallText } from 'components/atoms/Typography';
import { sum, toPairs } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import SmsIcon from '@mui/icons-material/Sms';
import { Box, ClickAwayListener, Menu, Tooltip, Typography, useTheme } from '@mui/material';
import ItemQuantityComponent from 'components/molecules/ItemQuantityComponent';
import { useDataTranslator } from 'hooks/useDataTranslator';
import { Cart, CartItem, FeeType } from 'klikni-jadi-shared-stuff';
import _ from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { useCartService } from 'services/cart/useCartService';
import { checkUrl } from 'services/images/utils';
import { getCartItemVisiblePrice } from 'services/utils';
import AddComment from './Comment';
import { useAlertDialog } from 'hooks/useAlertDialog';
import CartItemQuantityComponent from 'components/CartItemQuantityComponenet';
import Link from 'next/link';
import { getDetailsRouterArguments } from 'services/application';
import CartRestaurantSubtotal from './CartRestaurantSubtotal';

interface IOrderItemsProps {
    cart?: Cart;
}

const OrderItems = ({ cart }: IOrderItemsProps) => {
    const { t } = useTranslation('common');
    const { translate } = useDataTranslator();
    return (
        <Box mt={4} display="flex" flexDirection="column">
            <Headings.H2>{t('order_items')}</Headings.H2>
            {!cart.isEmpty && (
                <Box pt={2}>
                    {toPairs(cart.groupedItems)
                        .sort((a, b) => (a[0] > b[0] && 1) || -1)
                        .map(([resId, resItem]) => (
                            <Box key={resId} display="flex" flexDirection="column">
                                <Box
                                    sx={{
                                        backgroundColor: 'secondary.main',
                                        color: 'primary.main',
                                        textAlign: 'center',
                                    }}
                                    mb={1}
                                    px={1}
                                    py={0.5}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Link href={getDetailsRouterArguments(resId, resItem.restaurantName.en)}>
                                        <a>
                                            <MediumText semibold className="cursor-pointer" sx={{ textDecoration: 'underline' }}>
                                                {translate(resItem.restaurantName)}
                                            </MediumText>
                                        </a>
                                    </Link>
                                    {!cart?.coupon?.couponId && <CartRestaurantSubtotal item={resItem} />}
                                </Box>

                                {toPairs(resItem.items)
                                    .sort((a, b) => (a[0] > b[0] && 1) || -1)
                                    .map(([itemId, item], index) => (
                                        <Box display="flex" flexDirection="column" overflow="auto" key={itemId}>
                                            <CartItemListed
                                                key={itemId}
                                                item={item}
                                                itemId={itemId}
                                                index={index}
                                                restaurantId={resId}
                                                feeType={resItem.feeType}
                                            />
                                        </Box>
                                    ))}
                                {/* {!cart?.coupon?.couponId && <Roundup item={resItem} />} */}
                            </Box>
                        ))}
                </Box>
            )}
        </Box>
    );
};
interface ICartItemListedProps {
    item: CartItem;
    index: number;
    restaurantId: string;
    itemId: string;
    itemsQuantityComponentWidth?: number;
    quantityComponentPosition?: 'right' | 'image';
    imageWidth?: number;
    feeType: FeeType;
}
export const CartItemListed = ({
    item,
    restaurantId,
    itemId,
    itemsQuantityComponentWidth,
    quantityComponentPosition = 'right',
    imageWidth = 70,
    feeType,
}: ICartItemListedProps) => {
    const { alert } = useAlertDialog('REMOVE_FROM_CART');
    const messageRef = useRef<Element>();
    const [commentOpened, setCommentOpened] = useState<boolean>(false);
    const { t } = useTranslation('common');
    const { translate } = useDataTranslator();

    const { removeItem, addComment, changeQuantity, loadingPrice } = useCartService();
    const theme = useTheme();
    const [validImage, setValidImage] = useState<boolean>(false);

    const cartState = useSelector((state: RootState) => state.userCart);

    useEffect(() => {
        checkUrl(item.imageUrl)
            .then(() => {
                setValidImage(true);
            })
            .catch(() => {
                setValidImage(false);
            });
    }, []);

    const handleRemoveItem = () => {
        removeItem(restaurantId, itemId);
    };

    const handleQuantityChange = quantity => {
        changeQuantity(restaurantId, itemId, quantity);
    };

    const handleCloseCommenting = () => {
        setCommentOpened(false);
    };

    const handleOpenCommenting = () => {
        setCommentOpened(true);
    };

    const handleComment = (comment: string) => {
        if (cartState.data.groupedItems[restaurantId].items[itemId].comment !== comment) {
            addComment(restaurantId, itemId, comment);
        }
        handleCloseCommenting();
    };

    return (
        <Box
            key={item.id}
            display="flex"
            width="100%"
            sx={{
                // borderBottom: '1px solid #EDEDED',
                overflow: 'hidden',
            }}
            py={1}
        >
            {validImage && (
                <Box>
                    <Box
                        component="img"
                        src={item.imageUrl}
                        sx={{
                            width: imageWidth,
                            borderRadius: '10px',
                            height: (imageWidth / 70) * 50,
                            objectFit: 'cover',
                        }}
                    />
                    {quantityComponentPosition === 'image' && (
                        <CartItemQuantityComponent
                            onChange={handleQuantityChange}
                            initial={item?.quantity}
                            onRemoveItem={handleRemoveItem}
                            disabled={loadingPrice}
                            containerWidth={itemsQuantityComponentWidth}
                            valuePosition="center"
                        />
                    )}
                </Box>
            )}
            <Box flex={1} display="flex" flexDirection="column">
                <Box display="flex" alignItems="flex-start" flex={1} mb={1} lineHeight={1}>
                    <Box flex={1} px={0} pl={validImage ? 2 : 0}>
                        <Box maxWidth="90%">
                            <MediumText
                                sx={
                                    {
                                        // lineHeight: '1.125rem',
                                    }
                                }
                            >
                                {translate(item.name)}
                            </MediumText>
                        </Box>
                        {item.selectedPortionId && (
                            <Box>
                                <NormalText sx={{ color: '#6D7783' }}>
                                    {translate(item.portions[item.selectedPortionId].name)}
                                </NormalText>
                            </Box>
                        )}
                        <Box>
                            {item?.comment && (
                                <SmallText
                                    sx={{
                                        color: '#6D7783',
                                    }}
                                >
                                    {item?.comment}
                                </SmallText>
                            )}
                        </Box>
                        <Box>
                            {item?.excluded?.length > 0 && (
                                <SmallText sx={{ color: '#6D7783' }}>
                                    {t('excluded')}: {item.excluded.map(e => translate(e.name)).join(', ')}
                                </SmallText>
                            )}
                        </Box>
                        {item?.extras?.length > 0 && (
                            <Box>
                                <SmallText sx={{ color: '#6D7783' }}>
                                    {t('extra', { count: 2 })}:{' '}
                                    {item?.extras?.map(ex => `${ex.quantity} x ${translate(ex?.name)}`).join(', ')}
                                </SmallText>
                            </Box>
                        )}
                        {item?.prices?.itemCost !== item.prices.itemDiscountedPrice && (
                            <Box pt={1}>
                                <Box display="flex" gap={1}>
                                    <MediumText
                                        color="black"
                                        fontSize="1rem"
                                        lineHeight={1}
                                        sx={{
                                            textDecoration: 'line-through',
                                        }}
                                    >
                                        {item.prices.itemDiscountedPrice +
                                            sum((item.extras || []).map(e => e.prices.totalDiscountedPrice))}{' '}
                                        {t('mkd')}.
                                    </MediumText>
                                    <MediumText color="primary.main" fontSize="1rem" lineHeight={1}>
                                        {item?.prices?.itemCost + sum((item.extras || []).map(e => e.prices.totalCost))}{' '}
                                        {t('mkd')}.
                                    </MediumText>
                                </Box>
                                <Box mt={0.4}>
                                    <SmallText
                                        sx={{
                                            color: '#a0a0a0',
                                        }}
                                    >
                                        {t('discountedPrice')}
                                    </SmallText>
                                </Box>
                            </Box>
                        )}
                        {item?.prices?.itemCost === item.prices.itemDiscountedPrice && (
                            <Box pt={1} display="flex">
                                <MediumText color="primary.main" fontSize="1rem" lineHeight={1}>
                                    {item?.prices?.itemCost + sum((item.extras || []).map(e => e.prices.totalCost))} {t('mkd')}.
                                </MediumText>
                            </Box>
                        )}
                    </Box>
                    {(quantityComponentPosition === 'right' || !validImage) && (
                        <CartItemQuantityComponent
                            onChange={handleQuantityChange}
                            initial={item?.quantity}
                            onRemoveItem={handleRemoveItem}
                            disabled={loadingPrice}
                            containerWidth={itemsQuantityComponentWidth}
                        />
                    )}
                </Box>
            </Box>
            {/* <Menu anchorEl={messageRef.current} open={commentOpened} onClose={handleCloseCommenting}>
                <Box width="100%" px={2} py={1}>
                    <AddComment rows={2} onDone={handleComment} initialValue={item.comment} />
                </Box>
            </Menu> */}
        </Box>
    );
};

export default OrderItems;
